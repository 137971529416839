import { AppConfig, getApiUrl } from 'config';
import { AppDomain } from 'types/enums/AppDomain';
import { AppEnv } from 'types/enums/AppEnv';

let appConfig: AppConfig;

export class MirrorService {
  static init(hostname: string = document.location.hostname) {
    appConfig = new AppConfig(hostname);
    appConfig = {
      ...appConfig,
      apiUrl: getApiUrl(appConfig.apiUrl),
      originForInitialId: getApiUrl(appConfig.originForInitialId),
    };
    return appConfig;
  }

  static getInstance() {
    if (!appConfig) {
      this.init();
    }

    return appConfig;
  }

  static isCurrentSite(domain: AppDomain) {
    const instance = this.getInstance();
    return domain === instance.domain;
  }

  static get groupId() {
    const instance = this.getInstance();
    return instance.groupId;
  }

  static get domain() {
    const instance = this.getInstance();
    return instance.domain;
  }

  static get apiUrl() {
    const instance = this.getInstance();
    return instance.apiUrl;
  }

  static get wsUrl() {
    const instance = this.getInstance();
    return instance.wsUrl;
  }

  static get originForInitialId() {
    const instance = this.getInstance();
    return instance.originForInitialId;
  }

  static get originForTranslations() {
    const instance = this.getInstance();
    return instance.originForTranslations;
  }

  static get supportEmail() {
    const instance = this.getInstance();
    return instance.supportEmail;
  }

  static get supportPhone() {
    const instance = this.getInstance();
    return instance.supportPhone;
  }

  static get siteName() {
    const instance = this.getInstance();
    return instance.sitename;
  }

  static get gtmId() {
    const instance = this.getInstance();
    return instance.gtmId;
  }

  static get tiktokId() {
    const instance = this.getInstance();
    return instance.tiktokId;
  }

  static get googleAuthClientId() {
    const instance = this.getInstance();
    return instance.googleAuthClientId;
  }

  static get reCaptcha() {
    const instance = this.getInstance();
    return instance.reCaptcha;
  }

  static get sumsubToken() {
    const instance = this.getInstance();
    return instance.sumsubToken;
  }

  static get billingDescriptor() {
    if (this.isMeamo) {
      return 'mclub-pay.com';
    }
    if (this.isMatchess) {
      return 'mch-pay.com';
    }
    if (this.isUniteus) {
      return 'unite-pay.com';
    }
    if (this.isPpl2ppl) {
      return 'pplpays.com';
    }
    if (this.isTheunite) {
      return 'unite-pays.com';
    }
    if (this.isFinderSingles) {
      return 'fspays.net';
    }
    if (this.isJoyFinder) {
      return 'best-bill.com';
    }
    if (this.isFlirtGuru) {
      return 'smartcheck24.net';
    }
    if (this.isFlirtDiscovery) {
      return 'arrpays.com';
    }
    if (this.isAdventureRelation) {
      return 'adventure-relation.com';
    }
    if (this.isDreamsDate) {
      return 'epay2buy.com';
    }
    if (this.isNeverAlones) {
      return 'checkpay365.com';
    }
    if (this.isDateaSoul) {
      return 'open-bill.com';
    }
    if (this.isDateaSoulmate) {
      return 'theopenbill.com';
    }
    if (this.isYourLove4Life) {
      return 'epay2now.com';
    }
    if (this.isTiamo) {
      return 'checkcardus.com';
    }
    if (this.isSweatHeartSoul) {
      return 'mybillgo.com';
    }
    if (this.isRomanticWay) {
      return 'getbillgo.com';
    }
    if (this.isTheJoyDate) {
      return 'usdchecks.com';
    }
    if (this.isAdventuroUsDate) {
      return 'pays-go.com';
    }
    if (this.isLovePath) {
      return 'pay-act.com';
    }
    if (this.isGetParamour) {
      return 'smartcheck24.net';
    }
    if (this.isTripToLove) {
      return 'seepay24.net';
    }
    if (this.isLocalsJoy) {
      return 'best-bill.com';
    }
    if (this.isRelationshipNest) {
      return 'nstpay24.com';
    }
    if (this.isCupidKeys) {
      return 'cupidkeys.com';
    }
    if (this.isAmourPulse) {
      return 'amourpulse.com';
    }
    if (this.isMyConnectly) {
      return 'connectpay24.com';
    }
    if (this.isTenderTalkers) {
      return 'tnd-pay.com';
    }
    if (this.isBeValentine) {
      return 'bill4reg.com';
    }
    if (this.isOnesValentine) {
      return 'pay4reg.com';
    }
    if (this.isDayDreamme) {
      return 'charge4reg.com';
    }
    if (this.isDayDreamsDate) {
      return 'charge2reg.com';
    }
    if (this.isDelightedus) {
      return 'cost4reg.com';
    }
    if (this.isMeetLie) {
      return 'billact.me';
    }
    if (this.isKlubMe) {
      return 'pay-bill.me';
    }
    if (this.isPpls) {
      return 'authpay.me';
    }
    if (this.isHitchii) {
      return 'paymin.me';
    }
    if (this.isSupremeDate) {
      return 'billchecked.com';
    }
    if (this.isTheCupies) {
      return 'billchecked.com';
    }
    if (this.isLookLie) {
      return 'bill-act.com';
    }
    if (this.isPerfectDatings) {
      return 'hold-pay.com';
    }
    if (this.isDatingForLife) {
      return 'pay-ref.com';
    }
    if (this.isForeverLover) {
      return 'authref.com';
    }
    if (this.isFlirtingLover) {
      return 'act4paid.com';
    }
    if (this.isDatingsLover) {
      return 'billsarm.com';
    }
    if (this.isBestForFlirt) {
      return 'bst4flpay.com';
    }
    if (this.isPerfectFlirt) {
      return 'payto-me.com';
    }
    if (this.isSociableSpots) {
      return 'sociablespotspay.com';
    }
    if (this.isMeetNests) {
      return 'nestpay24.com';
    }
    if (this.isPeopleMeetClub) {
      return 'club24pay.com';
    }
    if (this.isGotLinkedNow) {
      return 'gotlinkpay.com';
    }
    if (this.isMeetHarbor) {
      return 'harborpays.com';
    }
    if (this.isRelationTunes) {
      return 'tunespay24.com';
    }
    if (this.isDateMateHub) {
      return 'matepays.com';
    }
    if (this.isLinkupNook) {
      return 'payupnook.com';
    }
    if (this.isTheLinkupers) {
      return 'thelinkpays.com';
    }
    if (this.isSoulMatesLab) {
      return 'pay24lab.com';
    }
    if (this.isFairyDates) {
      return 'fairybill.com';
    }
    if (this.isWaitForMe) {
      return 'formebill.com';
    }
    if (this.isLookFor4you) {
      return 'foryoubill.com';
    }
    if (this.isTheManWoman) {
      return 'mwbill24.com';
    }
    if (this.isUsTogether) {
      return 'billtogether24.com';
    }

    return 'billnow365.com';
  }

  static get isProd() {
    const instance = this.getInstance();
    return instance.env === AppEnv.Production;
  }

  static get isHookupers() {
    return this.isCurrentSite(AppDomain.Hookupers);
  }

  static get isBestHookuper() {
    return this.isCurrentSite(AppDomain.BestHookuper);
  }

  static get isHookupParadise() {
    return this.isCurrentSite(AppDomain.HookupParadise);
  }

  static get isDatesMe() {
    return this.isCurrentSite(AppDomain.DatesMe);
  }

  static get isMePlusYou() {
    return this.isCurrentSite(AppDomain.MePlusYou);
  }

  static get isLocalsDate() {
    return this.isCurrentSite(AppDomain.LocalsDate);
  }

  static get isLustStation() {
    return this.isCurrentSite(AppDomain.LustStation);
  }

  static get isLocalsJoy() {
    return this.isCurrentSite(AppDomain.Localsjoy);
  }

  static get isLocalsDateOrLustStation() {
    return this.isLocalsDate || this.isLustStation;
  }

  static get isMePlusYouOrLocalsjoy() {
    return this.isMePlusYou || this.isLocalsJoy;
  }

  static get isMidsOrDatesMe() {
    return this.isMidSite || this.isDatesMe;
  }

  static get isCleanSite() {
    return this.isDatesMe || this.isSimpleDates || this.isMidSite;
  }

  static get isArjalozMids() {
    return (
      this.isMeamo ||
      this.isMatchess ||
      this.isUniteus ||
      this.isPpl2ppl ||
      this.isTheunite
    );
  }

  // MIDS

  static get isMeamo() {
    return this.isCurrentSite(AppDomain.Meamo);
  }

  static get isMatchess() {
    return this.isCurrentSite(AppDomain.Matchess);
  }

  static get isUniteus() {
    return this.isCurrentSite(AppDomain.Uniteus);
  }

  static get isPpl2ppl() {
    return this.isCurrentSite(AppDomain.Ppl2ppl);
  }

  static get isTheunite() {
    return this.isCurrentSite(AppDomain.Theunite);
  }

  static get isFinderSingles() {
    return this.isCurrentSite(AppDomain.FinderSingles);
  }

  static get isJoyFinder() {
    return this.isCurrentSite(AppDomain.JoyFinder);
  }

  static get isFlirtGuru() {
    return this.isCurrentSite(AppDomain.FlirtGuru);
  }

  static get isFlirtDiscovery() {
    return this.isCurrentSite(AppDomain.FlirtDiscovery);
  }

  static get isAdventureRelation() {
    return this.isCurrentSite(AppDomain.AdventureRelation);
  }

  static get isDreamsDate() {
    return this.isCurrentSite(AppDomain.DreamsDate);
  }

  static get isNeverAlones() {
    return this.isCurrentSite(AppDomain.NeverAlones);
  }

  static get isDateaSoul() {
    return this.isCurrentSite(AppDomain.DateaSoul);
  }

  static get isDateaSoulmate() {
    return this.isCurrentSite(AppDomain.DateaSoulmate);
  }

  static get isYourLove4Life() {
    return this.isCurrentSite(AppDomain.YourLove4Life);
  }

  static get isTiamo() {
    return this.isCurrentSite(AppDomain.Tiamo);
  }

  static get isSimpleDatesMe() {
    return this.isCurrentSite(AppDomain.SimpleDatesMe);
  }

  static get isSweatHeartSoul() {
    return this.isCurrentSite(AppDomain.SweatHeartSoul);
  }

  static get isRomanticWay() {
    return this.isCurrentSite(AppDomain.RomanticWay);
  }

  static get isAdventuroUsDate() {
    return this.isCurrentSite(AppDomain.AdventuroUsDate);
  }

  static get isTheJoyDate() {
    return this.isCurrentSite(AppDomain.TheJoyDate);
  }

  static get isLovePath() {
    return this.isCurrentSite(AppDomain.LovePath);
  }

  static get isGetParamour() {
    return this.isCurrentSite(AppDomain.GetParamour);
  }

  static get isTripToLove() {
    return this.isCurrentSite(AppDomain.TripToLove);
  }

  static get isSimpleDatesCo() {
    return this.isCurrentSite(AppDomain.SimpleDatesCo);
  }

  static get isRelationshipNest() {
    return this.isCurrentSite(AppDomain.RelationshipNest);
  }

  static get isCupidKeys() {
    return this.isCurrentSite(AppDomain.CupidKeys);
  }

  static get isAmourPulse() {
    return this.isCurrentSite(AppDomain.AmourPulse);
  }

  static get isMyConnectly() {
    return this.isCurrentSite(AppDomain.MyConnectly);
  }

  static get isTenderTalkers() {
    return this.isCurrentSite(AppDomain.TenderTalkers);
  }

  static get isSupremeDate() {
    return this.isCurrentSite(AppDomain.SupremeDate);
  }

  static get isTheCupies() {
    return this.isCurrentSite(AppDomain.TheCupies);
  }

  static get isLookLie() {
    return this.isCurrentSite(AppDomain.LookLie);
  }

  static get isPerfectDatings() {
    return this.isCurrentSite(AppDomain.PerfectDatings);
  }

  static get isDatingForLife() {
    return this.isCurrentSite(AppDomain.DatingForLife);
  }

  static get isForeverLover() {
    return this.isCurrentSite(AppDomain.ForeverLover);
  }

  static get isFlirtingLover() {
    return this.isCurrentSite(AppDomain.FlirtingLover);
  }

  static get isDatingsLover() {
    return this.isCurrentSite(AppDomain.DatingsLover);
  }

  static get isBeValentine() {
    return this.isCurrentSite(AppDomain.BeValentine);
  }

  static get isOnesValentine() {
    return this.isCurrentSite(AppDomain.OnesValentine);
  }

  static get isDayDreamme() {
    return this.isCurrentSite(AppDomain.DayDreamme);
  }

  static get isDayDreamsDate() {
    return this.isCurrentSite(AppDomain.DayDreamsDate);
  }

  static get isDelightedus() {
    return this.isCurrentSite(AppDomain.Delightedus);
  }

  static get isBestForFlirt() {
    return this.isCurrentSite(AppDomain.BestForFlirt);
  }

  static get isPerfectFlirt() {
    return this.isCurrentSite(AppDomain.PerfectFlirt);
  }

  static get isMeetLie() {
    return this.isCurrentSite(AppDomain.MeetLie);
  }

  static get isKlubMe() {
    return this.isCurrentSite(AppDomain.KlubMe);
  }

  static get isPpls() {
    return this.isCurrentSite(AppDomain.Ppls);
  }

  static get isHitchii() {
    return this.isCurrentSite(AppDomain.Hitchii);
  }

  static get isSociableSpots() {
    return this.isCurrentSite(AppDomain.SociableSpots);
  }

  static get isMeetNests() {
    return this.isCurrentSite(AppDomain.MeetNests);
  }

  static get isPeopleMeetClub() {
    return this.isCurrentSite(AppDomain.PeopleMeetClub);
  }

  static get isGotLinkedNow() {
    return this.isCurrentSite(AppDomain.GotLinkedNow);
  }

  static get isMeetHarbor() {
    return this.isCurrentSite(AppDomain.MeetHarbor);
  }

  static get isRelationTunes() {
    return this.isCurrentSite(AppDomain.RelationTunes);
  }

  static get isDateMateHub() {
    return this.isCurrentSite(AppDomain.DateMateHub);
  }

  static get isLinkupNook() {
    return this.isCurrentSite(AppDomain.LinkupNook);
  }

  static get isTheLinkupers() {
    return this.isCurrentSite(AppDomain.TheLinkupers);
  }

  static get isSoulMatesLab() {
    return this.isCurrentSite(AppDomain.SoulMatesLab);
  }

  static get isFairyDates() {
    return this.isCurrentSite(AppDomain.FairyDates);
  }

  static get isWaitForMe() {
    return this.isCurrentSite(AppDomain.WaitForMe);
  }

  static get isLookFor4you() {
    return this.isCurrentSite(AppDomain.LookFor4you);
  }

  static get isTheManWoman() {
    return this.isCurrentSite(AppDomain.TheManWoman);
  }

  static get isUsTogether() {
    return this.isCurrentSite(AppDomain.UsTogether);
  }

  // DESCRIPTORS

  static get isTndPay() {
    return this.isCurrentSite(AppDomain.TndPay);
  }

  static get isAPay24() {
    return this.isCurrentSite(AppDomain.APay24);
  }

  static get isNstPay24() {
    return this.isCurrentSite(AppDomain.NstPay24);
  }

  static get isConnectPay24() {
    return this.isCurrentSite(AppDomain.ConnectPay24);
  }

  static get isLoveHorizonDescriptors() {
    return (
      this.isTndPay || this.isAPay24 || this.isNstPay24 || this.isConnectPay24
    );
  }

  static get isColinusMids() {
    return (
      this.isBeValentine ||
      this.isOnesValentine ||
      this.isDayDreamme ||
      this.isDayDreamsDate ||
      this.isDelightedus
    );
  }

  static get isStrayedatollMids() {
    return (
      this.isRelationTunes ||
      this.isDateMateHub ||
      this.isLinkupNook ||
      this.isTheLinkupers ||
      this.isSoulMatesLab
    );
  }

  static get isMidsFooterShow() {
    return (
      this.isStrayedatollMids ||
      this.isColinusMids ||
      this.isRelationshipNest ||
      this.isCupidKeys ||
      this.isAmourPulse ||
      this.isMyConnectly ||
      this.isTenderTalkers
    );
  }

  static get isSimpleDates() {
    return (
      this.isCurrentSite(AppDomain.SimpleDatesMe) ||
      this.isCurrentSite(AppDomain.SimpleDatesCo)
    );
  }

  static get isFirstSiteGroup() {
    return this.groupId === 1;
  }

  static get isSecondSiteGroup() {
    return this.groupId === 2;
  }

  static get isMidSite() {
    return this.groupId === 3;
  }

  static get isFourthSiteGroup() {
    return this.groupId === 4;
  }

  static get isFifthSiteGroup() {
    return this.groupId === 5;
  }

  static get isDescriptorGroupId() {
    return this.groupId === 6;
  }

  static get isGtmEnabled() {
    return Boolean(this.gtmId);
  }

  static get isVideosEnabled() {
    return !this.isMidSite;
  }

  static get isMainMirror() {
    return (
      this.isFirstSiteGroup || this.isSecondSiteGroup || this.isFourthSiteGroup
    );
  }

  static get isReadableSiteInfo() {
    return (
      this.isMeamo ||
      this.isMatchess ||
      this.isUniteus ||
      this.isPpl2ppl ||
      this.isTheunite ||
      this.isFlirtGuru ||
      this.isJoyFinder ||
      this.isRelationshipNest ||
      this.isCupidKeys ||
      this.isAmourPulse ||
      this.isMyConnectly ||
      this.isTenderTalkers ||
      this.isSupremeDate ||
      this.isTheCupies ||
      this.isLookLie ||
      this.isPerfectDatings ||
      this.isDatingForLife ||
      this.isForeverLover ||
      this.isFlirtingLover ||
      this.isDatingsLover ||
      this.isBeValentine ||
      this.isOnesValentine ||
      this.isDayDreamme ||
      this.isDayDreamsDate ||
      this.isLookLie ||
      this.isDelightedus ||
      this.isBestForFlirt ||
      this.isPerfectFlirt ||
      this.isMeetLie ||
      this.isKlubMe ||
      this.isPpls ||
      this.isHitchii ||
      this.isTripToLove ||
      this.isSociableSpots ||
      this.isMeetNests ||
      this.isPeopleMeetClub ||
      this.isGotLinkedNow ||
      this.isMeetHarbor ||
      this.isDreamsDate ||
      this.isNeverAlones ||
      this.isDateaSoul ||
      this.isDateaSoulmate ||
      this.isYourLove4Life ||
      this.isRelationTunes ||
      this.isDateMateHub ||
      this.isLinkupNook ||
      this.isTheLinkupers ||
      this.isSoulMatesLab ||
      this.isFairyDates ||
      this.isWaitForMe ||
      this.isLookFor4you ||
      this.isTheManWoman ||
      this.isUsTogether
    );
  }
}
